<template>
	<v-card flat>
		<v-toolbar dense :extended="extendToolbar" flat wrap>
			<w-layout align-center row>
				<SearchBar />
				<HeadersActions :expand.sync="extendToolbar" />
			</w-layout>
			<template v-if="extendToolbar" v-slot:extension>
				<HeadersFilters />
			</template>
		</v-toolbar>
		<w-divider />
	</v-card>
</template>

<script>
export default {
	name: 'ProjectsContainerHeader',
	components: {
		HeadersActions: () => ({
			component: import('@/components/Offers/CustomersManager/Header/CustomersManagerOffersActions')
		}),
		HeadersFilters: () => ({
			component: import('@/components/Offers/CustomersManager/Header/CustomersManagerOffersFilters')
		}),
		SearchBar: () => ({
			component: import('@/components/Offers/CustomersManager/Header/CustomersManagerOffersSearchBar')
		})
	},
	data: function () {
		return {
			extendToolbar: true
		}
	}
}
</script>
